@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Gilda+Display&family=Lato:ital,wght@0,300;0,400;0,700;1,300&display=swap");
header {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: position;
  -ms-transition: position;
  -o-transition: position; }
  header .top-header {
    height: 40px;
    padding: 12px;
    border-bottom: 1px solid #f8f5ee; }
    @media screen and (max-width: 990px) {
      header .top-header {
        height: 72px; } }
  header .info-top,
  header .nav-top {
    font-family: "Lato", sans-serif;
    font-weight: bold;
    font-size: 12px;
    color: #7e7e63;
    text-transform: uppercase; }
    header .info-top p,
    header .info-top a,
    header .nav-top p,
    header .nav-top a {
      color: #7e7e63;
      letter-spacing: 0.1em; }
      header .info-top p:first-child,
      header .info-top a:first-child,
      header .nav-top p:first-child,
      header .nav-top a:first-child {
        padding-right: 12px; }
      @media screen and (max-width: 990px) {
        header .info-top p,
        header .info-top a,
        header .nav-top p,
        header .nav-top a {
          padding-right: 32px !important; } }
      @media screen and (max-width: 768px) {
        header .info-top p,
        header .info-top a,
        header .nav-top p,
        header .nav-top a {
          font-size: 10px; } }
  header .main-header {
    margin: 16px 0; }
    header .main-header .row {
      margin: 0 auto; }
  header .main-navigation {
    display: flex;
    align-items: center; }
    header .main-navigation ul {
      display: flex;
      list-style: none;
      margin-bottom: 0;
      align-items: center; }
      header .main-navigation ul li {
        margin-right: 24px; }
        @media screen and (max-width: 990px) {
          header .main-navigation ul li {
            margin-right: 0;
            padding-left: 0 !important; } }
        @media screen and (max-width: 768px) {
          header .main-navigation ul li {
            margin-top: 2em; } }
        header .main-navigation ul li a {
          font-family: "Gilda Display", serif;
          color: #192c4b; }
  header.fixed {
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    padding-bottom: 12px;
    animation: smoothScroll 0.5s forwards; }
    header.fixed .main-header {
      margin: 8px 0 0; }

.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0; }

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none; }

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear; }

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear; }

.navbar-light .navbar-toggler-icon:after {
  top: 8px; }

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg); }

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg); }

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent; }

.nav-link {
  color: #192c4b !important;
  font-weight: 500;
  transition: all 200ms linear; }

.nav-item:hover .nav-link {
  color: #7e7e63 !important; }

.nav-item.active .nav-link {
  color: #192c4b !important; }

.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block; }

.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #7e7e63;
  opacity: 0;
  transition: all 200ms linear; }

.nav-item:hover:after {
  bottom: 0;
  opacity: 1; }

.nav-item.active:hover:after {
  opacity: 0; }

.nav-item {
  position: relative;
  transition: all 200ms linear; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px); }
  100% {
    transform: translateY(0px); } }

footer {
  background-color: #192c4b;
  color: #f8f5ee;
  padding: 4.5em 0;
  font-weight: 200; }
  footer ul {
    padding: 0;
    list-style: none;
    display: flex; }
  footer .sub-header {
    font-family: "Lato", sans-serif;
    color: #b6b69c;
    font-weight: bold; }
  footer .col-lg-5 div {
    margin-bottom: 2em; }
    footer .col-lg-5 div:last-child {
      margin-bottom: 0; }
  footer .social li {
    margin: 0 24px 0 0; }
    footer .social li a:hover {
      opacity: 0.5; }
    footer .social li:first-child {
      margin-left: 0; }

.footer-nav,
.contact-list {
  flex-direction: column;
  line-height: 2; }

.footer-nav a {
  color: #f8f5ee; }

.primary-button {
  background-color: #192c4b;
  border-radius: 24px;
  height: 50px;
  display: flex;
  align-items: center;
  font-family: "Lato", sans-serif !important;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #f8f5ee !important;
  padding: 18px 32px;
  justify-content: center;
  transition: all 0.2s ease-in; }
  .primary-button.header-button {
    padding: 18px 22px;
    margin-left: 1em; }
    @media screen and (max-width: 768px) {
      .primary-button.header-button {
        font-size: 10px; } }
  .primary-button:hover {
    background-color: #7e7e63;
    text-decoration: none; }

.secondary-button,
.form-link {
  border: 1px solid #7e7e63;
  box-sizing: border-box;
  border-radius: 24px;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7e7e63;
  padding: 12px 48px;
  display: inline-block;
  margin: 3em 0;
  transition: all 0.2s ease-in; }
  .secondary-button:hover,
  .form-link:hover {
    text-decoration: none;
    color: #f8f5ee;
    background-color: #7e7e63; }

.simple-link {
  color: #7e7e63;
  text-decoration: underline;
  transition: all 0.2s ease-in; }
  .simple-link:hover {
    color: #192c4b; }

.form-link.active {
  text-decoration: none;
  color: #f8f5ee;
  background-color: #7e7e63; }

.carousel-inner {
  max-height: 650px; }

.carousel-item {
  height: 100%;
  background-position: right;
  min-height: 50rem;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4); }

.slider-text {
  position: absolute;
  z-index: 2;
  color: #f8f5ee;
  top: 50%;
  left: 50%;
  width: 48%;
  transform: translate(-50%, -50%);
  height: 200px;
  overflow: hidden; }
  @media screen and (max-width: 990px) {
    .slider-text {
      width: 80%; } }
  @media screen and (max-width: 990px) {
    .slider-text h1 {
      font-size: 3rem; } }

@media screen and (max-width: 990px) {
  .events-home img {
    padding-bottom: 2em; } }

.top-container {
  padding: 0;
  margin-left: 1.5em; }

.top-item {
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;
  font-weight: 200; }
  .top-item::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: #7e7e63;
    position: absolute;
    margin-left: -20px;
    margin-top: 10px;
    transform: rotate(45deg); }

.steps {
  text-transform: uppercase; }
  .steps .step {
    display: flex;
    padding-right: 2em; }
    @media screen and (max-width: 990px) {
      .steps .step {
        width: 100%;
        padding-right: 0;
        text-align: center;
        justify-content: center; } }
    .steps .step:after {
      content: "";
      width: 1px;
      height: 75px;
      background-color: #7e7e63;
      position: relative;
      margin-left: 32px;
      margin-top: -16px; }
      @media screen and (max-width: 990px) {
        .steps .step:after {
          width: 0;
          display: none; } }
    .steps .step:last-child:after {
      width: 0; }

.center-slider {
  margin-bottom: 6em;
  margin-left: -20%; }
  @media screen and (max-width: 990px) {
    .center-slider {
      overflow-x: hidden; } }
  .center-slider .slide {
    margin-bottom: 4.5em; }
    @media screen and (max-width: 990px) {
      .center-slider .slide {
        margin-bottom: 1.5em; } }
    .center-slider .slide h2 {
      margin-top: 2em; }
  @media screen and (max-width: 990px) {
    .center-slider {
      margin-left: 0; } }
  .center-slider .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 6em;
    right: -9%; }
    @media screen and (max-width: 990px) {
      .center-slider .slick-dots {
        right: 0; } }
    .center-slider .slick-dots li {
      margin: 0 8px 0 0; }
      .center-slider .slick-dots li button:before {
        font-size: 12px;
        color: #b6b69c; }
    .center-slider .slick-dots .slick-active button:before {
      color: #7e7e63; }

.slick-slide:focus  {
  outline: none; }

.testimonial {
  margin-top: 3em; }

.social .sub-header {
  margin-top: 3em; }

.author {
  font-weight: bold;
  color: #b6b69c; }

.blog-thumb {
  height: 400px;
  background-size: cover;
  background-position: cover; }

.blog-content {
  background-color: white;
  padding: 24px; }
  @media screen and (max-width: 990px) {
    .blog-content {
      margin-bottom: 3em; } }
  .blog-content .date {
    font-size: 14px;
    font-weight: 300;
    color: #b6b69c;
    text-transform: uppercase; }
  .blog-content a {
    font-size: 14px;
    text-transform: uppercase;
    color: #b6b69c;
    text-decoration: underline;
    font-weight: bold;
    transition: all 0.2s ease-in; }
    .blog-content a:hover {
      color: #192c4b; }

.slider-container {
  position: relative; }
  .slider-container h2 {
    color: #f8f5ee;
    position: absolute;
    top: 35.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    text-align: center;
    z-index: 1;
    margin: 0;
    padding: 0; }

.experience-slider {
  margin-bottom: 48px; }
  .experience-slider .experience h4 {
    margin-top: 32px; }
  .experience-slider .experience-image {
    background-size: cover;
    min-height: 470px;
    background-position: center;
    position: relative; }
    @media screen and (max-width: 990px) {
      .experience-slider .experience-image {
        min-height: 350px; } }
    .experience-slider .experience-image h2 {
      color: #f8f5ee;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center; }
    .experience-slider .experience-image::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 470px;
      background-color: #192c4b;
      opacity: 0.5;
      z-index: 1; }
      @media screen and (max-width: 990px) {
        .experience-slider .experience-image::after {
          min-height: 350px; } }
  .experience-slider .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 96px;
    text-align: left; }
    .experience-slider .slick-dots li {
      margin: 0 8px 0 0; }
      .experience-slider .slick-dots li button:before {
        font-size: 12px;
        color: #b6b69c; }
    .experience-slider .slick-dots .slick-active button:before {
      color: #7e7e63; }

.experience-form {
  background-color: #f8f5ee;
  padding: 2em; }
  @media screen and (max-width: 1440px) {
    .experience-form {
      padding: 1.2em; } }
  .experience-form div > textarea {
    margin-bottom: 2em; }
    @media screen and (max-width: 1440px) {
      .experience-form div > textarea {
        margin-bottom: 1.5em; } }
  .experience-form div.form-group {
    margin-bottom: 2em; }
    @media screen and (max-width: 1440px) {
      .experience-form div.form-group {
        margin-bottom: 1em; } }
    @media screen and (max-width: 990px) {
      .experience-form div.form-group {
        margin-bottom: 3em; } }
  .experience-form .experience-button {
    font-size: 14px; }

.sidebar-section {
  position: absolute;
  height: 100%;
  width: 100%; }

.sidebar-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .sidebar-item .form-group {
    margin-bottom: 2em; }
  @media screen and (max-width: 990px) {
    .sidebar-item {
      position: relative; } }

.make-me-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0; }
  .make-me-sticky.scrolled {
    top: 8.5em; }
    .make-me-sticky.scrolled h4 {
      padding: 1em 0; }
  .make-me-sticky .m-16 {
    margin: 0; }

div.contact-section {
  margin: 2em 0 2em; }

.nav-item-contact {
  margin-right: 32px; }
  @media screen and (max-width: 990px) {
    .nav-item-contact {
      margin-right: 0; } }
  @media screen and (max-width: 990px) {
    .nav-item-contact:last-child {
      margin-bottom: 2em; } }

.form-link {
  color: #7e7e63; }
  @media screen and (max-width: 990px) {
    .form-link {
      margin: 1.5em 0; } }

.contact-form {
  margin-top: 48px; }

input#not-sure-checkbox {
  margin-left: 1em; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  box-sizing: border-box;
  font-size: 16px; }

ol,
li {
  list-style: none; }

body {
  width: 100%;
  height: 100vh;
  line-height: 1.625rem;
  overflow-x: hidden;
  background: white;
  color: #192c4b;
  font-weight: 200; }

h1,
h2,
h3,
h4 {
  font-family: "Gilda Display", serif;
  padding-bottom: 1em; }

h1 {
  font-size: 3.5rem; }
  @media screen and (max-width: 990px) {
    h1 {
      font-size: 2.5rem; } }

h2 {
  font-size: 2.625rem; }
  @media screen and (max-width: 990px) {
    h2 {
      font-size: 2rem; } }

h3 {
  font-size: 2rem; }
  @media screen and (max-width: 990px) {
    h3 {
      font-size: 1.5rem; } }

h4 {
  font-size: 1.5rem; }
  @media screen and (max-width: 990px) {
    h4 {
      font-size: 1rem; } }

img {
  max-width: 100%;
  object-fit: cover; }

b {
  font-weight: 700; }

.row {
  margin: 6em auto; }
  @media screen and (max-width: 990px) {
    .row {
      margin: 3em auto; } }
  .row .white-background {
    background-color: #f8f5ee; }

.m-72 {
  margin: 4.5em 0; }

.m-32 {
  margin: 2em 0; }

.m-16 {
  margin: 1em 0; }

.p-16 {
  padding: 0 1em; }

.p-32 {
  padding: 0 2em; }

.sub-header {
  color: #7e7e63;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 19px;
  letter-spacing: 0.1em; }

.white-background {
  background-color: #f8f5ee;
  padding: 6em 0; }
  @media screen and (max-width: 990px) {
    .white-background {
      padding: 3em 0; } }

.dark-background {
  background-color: #192c4b;
  padding: 96px 0;
  color: #f8f5ee; }

.fullscreen-image {
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: center center; }

.cover-image {
  width: 100%;
  min-height: 400px;
  background-size: cover;
  background-position: center center; }
  @media screen and (max-width: 990px) {
    .cover-image {
      min-height: 300px; } }

.about-item h4 {
  text-transform: uppercase;
  padding-bottom: 0; }

.form-section form {
  width: 100%; }
  @media screen and (max-width: 990px) {
    .form-section form {
      margin-top: 48px; } }

.form-section input[type="text"],
.form-section input[type="email"],
.form-section input[type="number"],
.form-section input[type="date"],
.form-section #datepicker-arrival,
.form-section #datepicker-departure,
.form-section textarea {
  color: #7e7e63;
  padding-bottom: 12px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #7e7e63;
  font-size: 14px;
  height: 36px;
  line-height: 30px;
  outline: none !important;
  width: 100%;
  text-transform: uppercase; }
  .form-section input[type="text"]::-webkit-input-placeholder,
  .form-section input[type="email"]::-webkit-input-placeholder,
  .form-section input[type="number"]::-webkit-input-placeholder,
  .form-section input[type="date"]::-webkit-input-placeholder,
  .form-section #datepicker-arrival::-webkit-input-placeholder,
  .form-section #datepicker-departure::-webkit-input-placeholder,
  .form-section textarea::-webkit-input-placeholder {
    font-family: "Lato", sans-serif;
    color: #7e7e63;
    opacity: 0.8; }
  .form-section input[type="text"]:-ms-input-placeholder,
  .form-section input[type="email"]:-ms-input-placeholder,
  .form-section input[type="number"]:-ms-input-placeholder,
  .form-section input[type="date"]:-ms-input-placeholder,
  .form-section #datepicker-arrival:-ms-input-placeholder,
  .form-section #datepicker-departure:-ms-input-placeholder,
  .form-section textarea:-ms-input-placeholder {
    font-family: "Lato", sans-serif;
    color: #7e7e63; }
  .form-section input[type="text"]:-moz-placeholder,
  .form-section input[type="email"]:-moz-placeholder,
  .form-section input[type="number"]:-moz-placeholder,
  .form-section input[type="date"]:-moz-placeholder,
  .form-section #datepicker-arrival:-moz-placeholder,
  .form-section #datepicker-departure:-moz-placeholder,
  .form-section textarea:-moz-placeholder {
    font-family: "Lato", sans-serif;
    color: #7e7e63; }
  .form-section input[type="text"]::-moz-placeholder,
  .form-section input[type="email"]::-moz-placeholder,
  .form-section input[type="number"]::-moz-placeholder,
  .form-section input[type="date"]::-moz-placeholder,
  .form-section #datepicker-arrival::-moz-placeholder,
  .form-section #datepicker-departure::-moz-placeholder,
  .form-section textarea::-moz-placeholder {
    font-family: "Lato", sans-serif;
    color: #7e7e63; }

.form-section input::-moz-focus-inner {
  border: 0; }

.form-section input::-webkit-outer-spin-button,
.form-section input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.form-section input[type="number"] {
  -moz-appearance: textfield; }

.form-section input[type="checkbox"] {
  visibility: hidden;
  margin-left: -10px; }

.form-section textarea {
  min-height: 100%;
  margin-bottom: 32px;
  height: 100px; }

.form-section label {
  color: #7e7e63;
  margin-right: 16px; }

@media screen and (max-width: 990px) {
  .form-section label.transfer-checkbox-text {
    margin-left: 0; } }

@media screen and (max-width: 990px) {
  .form-section label.escursione-checkbox-text {
    margin-left: 0;
    margin-bottom: 32px; } }

.form-section .checkbox {
  cursor: pointer; }

.form-section .checkbox-text {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #7e7e63; }

.form-section input[type="checkbox"] + label:before {
  border: 2px solid #7e7e63;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 20px;
  margin: 0 12px 0 0;
  padding: 0;
  vertical-align: top;
  width: 20px; }

.form-section input[type="checkbox"]:checked + label:before {
  background: #7e7e63;
  color: #f8f5ee;
  content: "\2713";
  text-align: center; }

.form-section input[type="checkbox"]:checked + label:after {
  font-weight: bold; }

.form-section input[type="checkbox"]:focus + label::before {
  outline: #3b99fc auto 5px; }

.form-section input#example-date-input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #7e7e63;
  margin-top: 6px;
  color: #484848;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  outline: none !important;
  width: 100%;
  border-radius: 0; }

.form-fields {
  padding: 0; }

.form-group {
  padding-left: 0; }
  @media screen and (max-width: 990px) {
    .form-group {
      margin-bottom: 3em; } }

.get-in-touch .form-group {
  margin-bottom: 3rem;
  padding-right: 1.5rem; }

.date-container:after {
  content: "";
  background-image: url(../img/calendar.svg);
  position: absolute;
  right: 24px;
  top: 0;
  width: 24px;
  height: 24px; }

.custom-select {
  background: transparent;
  color: #b6b69c;
  border: 1px solid #b6b69c;
  border-radius: 0;
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  min-height: 50px; }

.select:after {
  content: "";
  background-image: url("../img/arrow-down.svg");
  position: absolute;
  right: 40px;
  top: 22px;
  width: 11px;
  height: 6px; }

.input-placeholder {
  position: relative; }

.input-placeholder input {
  padding: 10px;
  font-size: 25px; }

.input-placeholder input:valid + .placeholder {
  display: none; }

.placeholder {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  color: #b6b69c;
  font-weight: 400; }

.modal {
  min-height: 650px;
  z-index: 99; }

.modal-backdrop {
  z-index: 2; }

.modal-content {
  background-color: #f8f5ee; }
  @media screen and (max-width: 990px) {
    .modal-content {
      padding: 3em 1.5em; } }
  @media screen and (max-width: 990px) {
    .modal-content img {
      width: 100%;
      max-height: 20rem;
      padding-bottom: 2em; } }
  .modal-content .secondary-button {
    margin: 24px 0; }
  .modal-content h3 {
    margin-bottom: 0; }
  .modal-content .form-section {
    margin-top: 2em; }

button.close {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 24px;
  color: #b6b69c;
  opacity: 1;
  transition: all 0.2s ease-in; }
  button.close:hover {
    color: #7e7e63; }

/* Datepicker UI */
.ui-datepicker-calendar .ui-state-active {
  border: 1px solid #7e7e63 !important;
  background: #7e7e63 !important;
  color: #f8f5ee !important;
  text-align: center !important; }

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #c5c5c5 !important;
  background: none !important;
  text-align: center !important; }

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:active {
  text-align: center !important; }

.ui-datepicker-calendar .ui-state-hover {
  background: #b6b69c !important; }

.date-picker {
  border-radius: 0 !important; }

[data-aos="fade-up-header"] {
  opacity: 0;
  transform: translateY(0.5rem);
  transition: opacity 1.5s cubic-bezier(0.65, 0.025, 0.67, 0.36), transform 1.5s cubic-bezier(0.65, 0.025, 0.67, 0.36);
  animation-delay: 0.5s;
  transition-delay: 1s; }
  [data-aos="fade-up-header"].aos-animate {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s cubic-bezier(0.34, 0.615, 0.4, 0.985), transform 1.5s cubic-bezier(0.34, 0.615, 0.4, 0.985);
    animation-delay: 0.5s;
    transition-delay: 1s; }
