footer {
  background-color: $navy-blue;
  color: $floral-white;
  padding: 4.5em 0;
  font-weight: 200;
  ul {
    padding: 0;
    list-style: none;
    display: flex;
  }
  .sub-header {
    font-family: $body-font;
    color: $bone;
    font-weight: bold;
  }
  .col-lg-5 {
    div {
      margin-bottom: 2em;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .social {
    li {
      margin: 0 24px 0 0;
      a {
        &:hover {
          opacity: 0.5;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.footer-nav,
.contact-list {
  flex-direction: column;
  line-height: 2;
}

.footer-nav {
  a {
    color: $floral-white;
  }
}
