// .experience {
//   img {
//     min-height: 470px;
//     object-fit: cover;
//   }
// }

.slider-container {
  position: relative;
  h2 {
    color: $floral-white;
    position: absolute;
    top: 35.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    text-align: center;
    z-index: 1;
    margin: 0;
    padding: 0;
  }
}

.experience-slider {
  margin-bottom: 48px;
  .experience {
    h4 {
      margin-top: 32px;
    }
  }
  .experience-image {
    background-size: cover;
    min-height: 470px;
    @media screen and (max-width: 990px) {
      min-height: 350px;
    }
    background-position: center;
    position: relative;
    h2 {
      color: $floral-white;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      text-align: center;
    }
    &::after {
      content: ""; // ::before and ::after both require content
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 470px;
      @media screen and (max-width: 990px) {
        min-height: 350px;
      }
      background-color: $navy-blue;
      opacity: 0.5;
      z-index: 1;
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 96px;
    text-align: left;
    li {
      margin: 0 8px 0 0;
      button {
        &:before {
          font-size: 12px;
          color: $bone;
        }
      }
    }
    .slick-active {
      button {
        &:before {
          color: $gold-fusion;
        }
      }
    }
  }
}

.experience-form {
  background-color: $floral-white;
  padding: 2em;
  @media screen and (max-width: 1440px) {
    padding: 1.2em;
  }
  div > textarea {
    margin-bottom: 2em;
    @media screen and (max-width: 1440px) {
      margin-bottom: 1.5em;
    }
  }
  div.form-group {
    margin-bottom: 2em;
    @media screen and (max-width: 1440px) {
      margin-bottom: 1em;
    }
    @media screen and (max-width: 990px) {
      margin-bottom: 3em;
    }
  }
  .experience-button {
    font-size: 14px;
  }
}

.sidebar-section {
  position: absolute;
  height: 100%;
  width: 100%;
}

.sidebar-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .form-group {
    margin-bottom: 2em;
  }
  @media screen and (max-width: 990px) {
    position: relative;
  }
}

.make-me-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  &.scrolled {
    top: 8.5em;
    h4 {
      padding: 1em 0;
    }
  }
  .m-16 {
    margin: 0;
  }
}

// button.slick-next {
//   right: 5%;
//   width: 32px;
//   height: 32px;
// }

// button.slick-prev {
//   left: 5%;
//   width: 32px;
//   height: 32px;
// }
