.primary-button {
  background-color: $navy-blue;
  border-radius: 24px;
  height: 50px;
  display: flex;
  align-items: center;
  font-family: $body-font !important;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: $floral-white !important;
  padding: 18px 32px;
  justify-content: center;
  transition: all 0.2s ease-in;
  &.header-button {
    padding: 18px 22px;
    margin-left: 1em;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
  &:hover {
    background-color: $gold-fusion;
    text-decoration: none;
  }
}

.secondary-button,
.form-link {
  border: 1px solid $gold-fusion;
  box-sizing: border-box;
  border-radius: 24px;
  font-family: $body-font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $gold-fusion;
  padding: 12px 48px;
  display: inline-block;
  margin: 3em 0;
  transition: all 0.2s ease-in;
  &:hover {
    text-decoration: none;
    color: $floral-white;
    background-color: $gold-fusion;
  }
}

.simple-link {
  color: $gold-fusion;
  text-decoration: underline;
  transition: all 0.2s ease-in;
  &:hover {
    color: $navy-blue;
  }
}

.form-link {
  &.active {
    text-decoration: none;
    color: $floral-white;
    background-color: $gold-fusion;
  }
}
