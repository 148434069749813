.carousel-inner {
  max-height: 650px;
}

.carousel-item {
  height: 100%;
  background-position: right;
  min-height: 50rem;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}

.slider-text {
  position: absolute;
  z-index: 2;
  color: $floral-white;
  top: 50%;
  left: 50%;
  width: 48%;
  transform: translate(-50%, -50%);
  height: 200px;
  overflow: hidden;
  @media screen and (max-width: 990px) {
    width: 80%;
  }
  h1 {
    @media screen and (max-width: 990px) {
      font-size: 3rem;
    }
  }
}

.events-home {
  img {
    @media screen and (max-width: 990px) {
      padding-bottom: 2em;
    }
  }
}

.top-container {
  padding: 0;
  margin-left: 1.5em;
}

.top-item {
  list-style: none;
  margin: 24px 0 0 0;
  padding: 0;
  font-weight: 200;
  &::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: $gold-fusion;
    position: absolute;
    margin-left: -20px;
    margin-top: 10px;
    transform: rotate(45deg);
  }
}

.steps {
  text-transform: uppercase;
  .step {
    display: flex;
    padding-right: 2em;
    @media screen and (max-width: 990px) {
      width: 100%;
      padding-right: 0;
      text-align: center;
      justify-content: center;
    }
    &:after {
      @media screen and (max-width: 990px) {
        width: 0;
        display: none;
      }
      content: "";
      width: 1px;
      height: 75px;
      background-color: $gold-fusion;
      position: relative;
      margin-left: 32px;
      margin-top: -16px;
    }
    &:last-child {
      &:after {
        width: 0;
      }
    }
  }
}

.center-slider {
  margin-bottom: 6em;
  @media screen and (max-width: 990px) {
    overflow-x: hidden;
  }
  .slide {
    margin-bottom: 4.5em;
    @media screen and (max-width: 990px) {
      margin-bottom: 1.5em;
    }
    h2 {
      margin-top: 2em;
    }
  }
  margin-left: -20%;
  @media screen and (max-width: 990px) {
    margin-left: 0;
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 6em;
    right: -9%;
    @media screen and (max-width: 990px) {
      right: 0;
    }
    li {
      margin: 0 8px 0 0;
      button {
        &:before {
          font-size: 12px;
          color: $bone;
        }
      }
    }
    .slick-active {
      button {
        &:before {
          color: $gold-fusion;
        }
      }
    }
  }
}

.slick-slide:focus  {
  outline: none;
}

.testimonial {
  margin-top: 3em;
}

.social {
  .sub-header {
    margin-top: 3em;
  }
}

.author {
  font-weight: bold;
  color: $bone;
}

.blog-thumb {
  height: 400px;
  background-size: cover;
  background-position: cover;
}

.blog-content {
  background-color: white;
  padding: 24px;
  @media screen and (max-width: 990px) {
    margin-bottom: 3em;
  }
  .date {
    font-size: 14px;
    font-weight: 300;
    color: $bone;
    text-transform: uppercase;
  }
  a {
    font-size: 14px;
    text-transform: uppercase;
    color: $bone;
    text-decoration: underline;
    font-weight: bold;
    transition: all 0.2s ease-in;
    &:hover {
      color: $navy-blue;
    }
  }
}
