@import "variables.scss";
@import "header.scss";
@import "footer.scss";
@import "buttons.scss";

@import "home.scss";
@import "experiences.scss";
@import "contact.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

ol,
li {
  list-style: none;
}

body {
  width: 100%;
  height: 100vh;
  line-height: 1.625rem;
  overflow-x: hidden;
  background: white;
  color: $navy-blue;
  font-weight: 200;
}

h1,
h2,
h3,
h4 {
  font-family: $title-font;
  padding-bottom: 1em;
}

h1 {
  font-size: 3.5rem;
  @media screen and (max-width: 990px) {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 2.625rem;
  @media screen and (max-width: 990px) {
    font-size: 2rem;
  }
}

h3 {
  font-size: 2rem;
  @media screen and (max-width: 990px) {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 1.5rem;
  @media screen and (max-width: 990px) {
    font-size: 1rem;
  }
}

img {
  max-width: 100%;
  object-fit: cover;
}

b {
  font-weight: 700;
}

.row {
  margin: 6em auto;
  @media screen and (max-width: 990px) {
    margin: 3em auto;
  }
  .white-background {
    background-color: $floral-white;
  }
}

.m-72 {
  margin: 4.5em 0;
}

.m-32 {
  margin: 2em 0;
}

.m-16 {
  margin: 1em 0;
}

.p-16 {
  padding: 0 1em;
}

.p-32 {
  padding: 0 2em;
}

.sub-header {
  color: $gold-fusion;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 19px;
  letter-spacing: 0.1em;
}

.white-background {
  background-color: $floral-white;
  padding: 6em 0;
  @media screen and (max-width: 990px) {
    padding: 3em 0;
  }
}

.dark-background {
  background-color: $navy-blue;
  padding: 96px 0;
  color: $floral-white;
}

.fullscreen-image {
  width: 100%;
  height: 700px;
  background-size: cover;
  background-position: center center;
}

.cover-image {
  width: 100%;
  min-height: 400px;
  @media screen and (max-width: 990px) {
    min-height: 300px;
  }
  background-size: cover;
  background-position: center center;
}

.about-item {
  h4 {
    text-transform: uppercase;
    padding-bottom: 0;
  }
}

.form-section {
  form {
    width: 100%;
    @media screen and (max-width: 990px) {
      margin-top: 48px;
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  #datepicker-arrival,
  #datepicker-departure,
  textarea {
    color: $gold-fusion;
    padding-bottom: 12px;
    background: transparent;
    border: 0;
    border-bottom: 1px solid $gold-fusion;
    font-size: 14px;
    height: 36px;
    line-height: 30px;
    outline: none !important;
    width: 100%;
    text-transform: uppercase;
    &::-webkit-input-placeholder {
      font-family: $body-font;
      color: $gold-fusion;
      opacity: 0.8;
    }
    &:-ms-input-placeholder {
      font-family: $body-font;
      color: $gold-fusion;
    }
    &:-moz-placeholder {
      font-family: $body-font;
      color: $gold-fusion;
    }
    &::-moz-placeholder {
      font-family: $body-font;
      color: $gold-fusion;
    }
  }
  input::-moz-focus-inner {
    border: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="checkbox"] {
    visibility: hidden;
    margin-left: -10px;
  }
  textarea {
    min-height: 100%;
    margin-bottom: 32px;
    height: 100px;
  }
  label {
    color: $gold-fusion;
    margin-right: 16px;
  }
  label.transfer-checkbox-text {
    @media screen and (max-width: 990px) {
      margin-left: 0;
    }
  }
  label.escursione-checkbox-text {
    @media screen and (max-width: 990px) {
      margin-left: 0;
      margin-bottom: 32px;
    }
  }
  .checkbox {
    cursor: pointer;
  }
  .checkbox-text {
    font-size: 14px;
    font-family: $body-font;
    color: $gold-fusion;
  }
  input[type="checkbox"] + label:before {
    border: 2px solid $gold-fusion;
    content: "\00a0";
    display: inline-block;
    font: 16px/1em sans-serif;
    height: 20px;
    margin: 0 12px 0 0;
    padding: 0;
    vertical-align: top;
    width: 20px;
  }
  input[type="checkbox"]:checked + label:before {
    background: $gold-fusion;
    color: $floral-white;
    content: "\2713";
    text-align: center;
  }
  input[type="checkbox"]:checked + label:after {
    font-weight: bold;
  }

  input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
  input#example-date-input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid $gold-fusion;
    margin-top: 6px;
    color: #484848;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    outline: none !important;
    width: 100%;
    border-radius: 0;
  }
  // a {
  //   color: $navy-blue;
  // }
}

.form-fields {
  padding: 0;
}
.form-group {
  padding-left: 0;
  @media screen and (max-width: 990px) {
    margin-bottom: 3em;
  }
}

.get-in-touch {
  .form-group {
    margin-bottom: 3rem;
    padding-right: 1.5rem;
    // &:last-child {
    //   margin-bottom: 0;
    // }
  }
}

.date-container {
  &:after {
    content: "";
    background-image: url(../img/calendar.svg);
    position: absolute;
    right: 24px;
    top: 0;
    width: 24px;
    height: 24px;
  }
}

.custom-select {
  background: transparent;
  color: $bone;
  border: 1px solid $bone;
  border-radius: 0;
  width: 100%;
  text-transform: uppercase;
  text-align: left;
  font-size: 1rem;
  font-family: $body-font;
  min-height: 50px;
}

.select {
  &:after {
    content: "";
    background-image: url("../img/arrow-down.svg");
    position: absolute;
    right: 40px;
    top: 22px;
    width: 11px;
    height: 6px;
  }
}

.input-placeholder {
  position: relative;
}
.input-placeholder input {
  padding: 10px;
  font-size: 25px;
}
.input-placeholder input:valid + .placeholder {
  display: none;
}

.placeholder {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
  font-family: $body-font;
  font-size: 1rem;
  color: $bone;
  font-weight: 400;
}

.modal {
  min-height: 650px;
  z-index: 99;
}

.modal-backdrop {
  z-index: 2;
}

.modal-content {
  background-color: $floral-white;
  @media screen and (max-width: 990px) {
    padding: 3em 1.5em;
  }
  img {
    @media screen and (max-width: 990px) {
      width: 100%;
      max-height: 20rem;
      padding-bottom: 2em;
    }
  }
  .secondary-button {
    margin: 24px 0;
  }
  h3 {
    margin-bottom: 0;
  }
  .form-section {
    margin-top: 2em;
  }
}

button.close {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 24px;
  color: $bone;
  opacity: 1;
  transition: all 0.2s ease-in;
  &:hover {
    color: $gold-fusion;
  }
}

/* Datepicker UI */

.ui-datepicker-calendar .ui-state-active {
  border: 1px solid $gold-fusion !important;
  background: $gold-fusion !important;
  color: $floral-white !important;
  text-align: center !important;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #c5c5c5 !important;
  background: none !important;
  text-align: center !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:active {
  text-align: center !important;
}

.ui-datepicker-calendar .ui-state-hover {
  background: $bone !important;
}

.date-picker {
  border-radius: 0 !important;
}

[data-aos="fade-up-header"] {
  opacity: 0;
  transform: translateY(0.5rem);
  transition: opacity 1.5s cubic-bezier(0.65, 0.025, 0.67, 0.36),
    transform 1.5s cubic-bezier(0.65, 0.025, 0.67, 0.36);
  animation-delay: 0.5s;
  transition-delay: 1s;
  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 1.5s cubic-bezier(0.34, 0.615, 0.4, 0.985),
      transform 1.5s cubic-bezier(0.34, 0.615, 0.4, 0.985);
    animation-delay: 0.5s;
    transition-delay: 1s;
  }
}
