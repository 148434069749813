header {
  .top-header {
    height: 40px;
    padding: 12px;
    border-bottom: 1px solid $floral-white;
    @media screen and (max-width: 990px) {
      height: 72px;
    }
  }

  .info-top,
  .nav-top {
    font-family: $body-font;
    font-weight: bold;
    font-size: 12px;
    color: $gold-fusion;
    text-transform: uppercase;
    p,
    a {
      color: $gold-fusion;
      letter-spacing: 0.1em;
      &:first-child {
        padding-right: 12px;
      }
      @media screen and (max-width: 990px) {
        padding-right: 32px !important;
      }
      @media screen and (max-width: 768px) {
        font-size: 10px;
      }
    }
  }

  .main-header {
    // .navbar-brand {
    //   order: 0;
    // }
    // .navbar-toggler {
    //   order: -1;
    // }
    // .primary-button.header-button {
    //   order: 3;
    // }
    // @media screen and (min-width: 990px) {
    //   .primary-button.header-button {
    //     margin-left: 1em;
    //   }
    // }
    margin: 16px 0;
    .row {
      margin: 0 auto;
    }
  }
  .main-navigation {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      list-style: none;
      margin-bottom: 0;
      align-items: center;
      li {
        margin-right: 24px;
        @media screen and (max-width: 990px) {
          margin-right: 0;
          padding-left: 0 !important;
        }
        @media screen and (max-width: 768px) {
          margin-top: 2em;
        }
        a {
          font-family: $title-font;
          color: $navy-blue;
        }
      }
    }
  }
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: position;
  -ms-transition: position;
  -o-transition: position;
  &.fixed {
    position: fixed;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    padding-bottom: 12px;
    animation: smoothScroll 0.5s forwards;
    .main-header {
      margin: 8px 0 0;
    }
  }
}

.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.nav-link {
  color: $navy-blue !important;
  font-weight: 500;
  transition: all 200ms linear;
}
.nav-item:hover .nav-link {
  color: $gold-fusion !important;
}
.nav-item.active .nav-link {
  color: $navy-blue !important;
}
.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}
.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: $gold-fusion;
  opacity: 0;
  transition: all 200ms linear;
}
.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}
.nav-item.active:hover:after {
  opacity: 0;
}
.nav-item {
  position: relative;
  transition: all 200ms linear;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
