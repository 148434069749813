div.contact-section {
  margin: 2em 0 2em;
}

.nav-item-contact {
  margin-right: 32px;
  @media screen and (max-width: 990px) {
    margin-right: 0;
  }
  &:last-child {
    @media screen and (max-width: 990px) {
      margin-bottom: 2em;
    }
  }
}

.form-link {
  color: $gold-fusion;
  @media screen and (max-width: 990px) {
    margin: 1.5em 0;
  }
}

.contact-form {
  margin-top: 48px;
}

input#not-sure-checkbox {
  margin-left: 1em;
}
